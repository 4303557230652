/* .img-wrapper{
  overflow-x: hidden;
}
.img-container {
  margin-top: 50px;
    display: flex;
    width: 100%;
    overflow-x: auto; 
  }
  
  .box {
    flex: 0 0 auto; 
    margin-right: -50px; 
  }
  
  .box:last-child {
    margin-right: 0; 
  }
  
  .box img {
    width: calc(100% + 50px); 
    height: 70%;
  }

  .box:hover {
    margin-right: 0; 
  }
  
  .box:hover img {
    width: 100%; 
    max-height: none;
  }
  .header{
    text-align: center;
  }
   */
   /* Add to your LandingHoverimg.css */

/* Container for the image and text */

   .scroll-container {
    overflow-x: scroll; 
    overflow-y: hidden;
    display: flex;
    align-items: center;
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none; 
  }

  .scrollinside{
    flex:0 0 auto;
  }
.imagegray{
  filter: grayscale(100%);
  -webkit-filter:grayscale(100%);
  transition: width 0.3s ease, margin 0.3s ease;
  
}

.imagegray:hover{
  filter: none;
  -webkit-filter: none;
  width: 350px;
  margin: 0px 20px 0px 0px;
  
}
.backtotop{
  font-family: Roboto;
  font-weight: 500;
  color: black;
}
.header{
  text-align: center;
}

