.card-pdt-ctgry {
    margin-top: 20px;
  }
  .Card-text{
    font-family: "Roboto";
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  .Card-subtext{
    font-family: "Roboto";
    font-weight: 400;
    color: black;
  }
  .Card-price{
    font-family: "Roboto";
    font-weight: 600;
  }
.dot{
    margin-top: 6px;
        height: 13px;
        width: 13px;
        background-color:#FF9900;
        border-radius: 50%;
}
.dot-1{
    margin-top: 5px;
        height: 13px;
        width: 13px;
        background-color: #D40000;
        border-radius: 50%;
}
.search{
    margin-top: 30px;
}