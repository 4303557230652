.NewOrderPage{
    /* background: white; */
    margin-left:"25px";
    margin-right: "25px";
}
.btnStyles{
    display: flex;
    gap:30px;
}
.neworderBox{
    width: 100%;
    height: 60px;
    background-color: #85BE4940;
    border-radius: 15px;
    margin-top: 20px;
    display: flex; /* Add flex display */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: "Roboto";
}
.textStyleNew{
    font-weight: 550;
    font-family: "Roboto";
    text-align: center;
    
}
  
.gridStyle{
    
        display: grid;
        column-gap: 200px;
        margin-top: 30px;
        grid-template-columns: 100px 300px 700px;
        width: 90%;
        margin-left: 80px;
    
}
.image_container{
    width: 100%;
    /* margin-left: 80px; */
    margin-top: 30px;
}
.gridStyle2{
    
    display: grid;
    column-gap: 520px;
    margin-top: 30px;
    grid-template-columns: auto auto;
    width: 90%;
    margin-left: 80px;

}
.image_container2{
    width: 100%;
    /* margin-left: 180px; */
    /* margin-top: 10px; */
}
.gridStyle3 {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    gap: 30px;
    width: 60%;
    margin-left: 0px;
    text-transform: lowercase;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Adjust button padding and height for smaller screens */
  .gridStyle3 div {
    flex: 1;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .gridStyle3 {
        display: flex;
      /* flex-direction: column; */
      justify-content: space-around;
      gap: 20px;
      width: 60%;
      margin-left: 0;
    }
  
    .gridStyle3 div {
      width: 60%;
    }
  
    .gridStyle3 .CustomButton {
      padding: 0px 15px;
      height: 40px;
    }
  }
  
  /* Extra small screens */
  @media (max-width: 480px) {
    .gridStyle3 {
      gap: 10px;
    }
  
    .gridStyle3 .CustomButton {
      font-size: 14px;
      padding: 0px 10px;
      height: 35px;
    }
  }
  
.helpTag{
    color: #85BE49;
    margin: auto;
    text-decoration: none;
}
#buttonContainer {
  display: flex;
 justify-content: center;
 gap: 30px; /* Adjust the distance between buttons as needed */
}

.MainActiveOrder{
    display: flex;
    
    font-family: "Roboto";
     font-weight: 400;
     color: #646464;
}
.MainActive{
    font-family: "Roboto";
    font-weight: 400;
    color: #646464;
}
.nameStyle{
    font-family: "Nunito";
    font-weight: 700;
    padding-left: 0px;
}
.address{
    /* margin-left: 8px; */
     font-weight: 700;
     font-family: "Roboto";
     padding-left: 0px;
}
.numberStyle1{
    margin-right: 0px;
    font-weight: 700;
    font-family: "Roboto";
    /* margin-left: 10px; */
}
    
.MainActives{
  
    display: flex;
    /* gap: 3px; */
    font-family: "Roboto";
    font-weight: 400;
    
    color: #85BE49;
  
  
}
.productDetailsNew{
    font-weight: 500;
    font-family: "Roboto";
    margin-top: 10px;
    
}