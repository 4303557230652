/* Container for image and card alignment */
.container_12 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;
  gap:"70px";
  
}

.font-land{
  padding-top: 20px;
    font-family: 'Roboto';
    font-weight: 700;
    color: #659B1B;
}
.font-land1{
    font-family: 'Roboto';
    font-weight: 700;
    color: black;
}
.font_para{
  font-family: 'Roboto';
}

/* Initial state for the image and card before animation */
.image-container {
  
  opacity: 1; /* Initially hidden */
  transform: translateX(-19px); /* Image starts from left */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.content-cardabout {
  opacity: 1; /* Initially hidden */
  transform: translateX(100px); /* Card starts from right */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  
}

/* Apply "show" class to animate elements on scroll */
.image-container.show {
  opacity: 1;
  transform: translateX(0);
}

.content-cardabout.show {
  opacity: 1;
  transform: translateX(0); /* Move to original position */
}

/* Flex container for card content styling */
.content-cardabout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: #F2F2F2;
  border-radius: 20px;
}

.image-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
/* Font styling */
.font-land {
  font-weight: bold;
}

.font-land1 {
  color: #555;
}

/* Button styling */
.custom-button1 {
  color: #ffffff;
  font-weight: bold;
  border-radius: 20px;
  background: linear-gradient(to right, #13801C, #FFFFFF);
  text-transform: none;
}

/* Dialog modal adjustments */
.MuiDialog-container .MuiPaper-root {
  padding: 20px;
  border-radius: 20px;
}

.headerstylelearn {
  font-weight: bold;
  text-align: center;
}

.content-card-1 {
  color: #666;
  line-height: 1.6;
  text-align: justify;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .container_12 {
    flex-direction: column;
  }
  .image-container,
  .content-cardabout {
    width: 100%;
    padding: 10px;
  }
  .font-land {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .container_12 {
    flex-direction: row;
    padding: 15px;
  }
  .image-container,
  .content-cardabout {
    max-width: 90%;
    opacity: 1;
  }
}

/* Medium screens */
@media (max-width: 992px) {
  .container_12 {
    flex-direction: column;
  }
  .image-container, .content-cardabout {
    width: 80%;
    padding: 10px;
  }
}

/* Small screens */
@media (max-width: 768px) {
  .font-land {
    font-size: 24px;
  }
  .custom-button1 {
    width: 100px;
  }
  .content-cardabout {
    padding: 20px;
    gap: 15px;
    width:"60%";
  }
  .image-container{
    width:"70%"
  }
}

/* Extra Small screens */
@media (max-width: 576px) {
  .container_12 {
    padding: 10px;
    flex-direction: column;
  }
  .content-cardabout {
    padding: 10px;
  }
  .font-land {
    font-size: 20px;
  }
  .custom-button1 {
    width: 80px;
    font-size: 12px;
  }
  .image-container{
    width:"40%";
    
     
  }
}